import { useLocalEnd, useLocalStart, useNotifyErrors, useTableOptions } from '@/composables'
import { exportOrders as exportOrdersApi, fetchOrders } from '@api/order'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useInvoicesList() {
  const invoiceListTable = ref([])

  // Table Handlers
  const tableColumns = [
    {
      text: '#ID',
      align: 'start',
      value: 'id',
    },
    { text: 'User', value: 'user', sortable: false },
    { text: 'Nature', value: 'nature', sortable: false },
    { text: 'Merchant', value: 'merchant', sortable: false },
    { text: 'DIST.', value: 'distributor_id', sortable: false },
    { text: 'TOTAL', value: 'total' },
    { text: 'DATE', value: 'created_at' },
    { text: 'STATUS', value: 'status' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const emailQuery = ref(cached[2] || '')
  const statusFilter = ref(cached[3] || null)
  const distributorIdFilter = ref(cached[4] || null)
  const createdAtFromFilter = ref(cached[5] || null)
  const createdAtToFilter = ref(cached[6] || null)
  const merchantIdFilter = ref(cached[7] || null)
  const totalInvoiceListTable = ref(0)
  const loading = ref(false)
  const selectedTableData = ref([])

  const getQuery = () =>
    useTableOptions(options.value, {
      search: searchQuery.value,
      email: emailQuery.value,
      status: statusFilter.value,
      distributor_id: distributorIdFilter.value,
      merchant_id: merchantIdFilter.value,
      created_at_from: useLocalStart(createdAtFromFilter.value, 'day'),
      created_at_to: useLocalEnd(createdAtToFilter.value, 'day'),
    })

  const loadOrders = () =>
    fetchOrders(getQuery())
      .then(({ data }) => {
        const { records, pagination } = data.data
        invoiceListTable.value = records
        totalInvoiceListTable.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const exportOrders = async (query = {}) => {
    const params = query
    delete params.take
    delete params.page

    loading.value = true

    await exportOrdersApi(params)
      .then()
      .finally(() => {
        loading.value = false
      })
      .catch(error => {
        console.log(error)
        useNotifyErrors(error)
      })
  }

  const cachable = [options, searchQuery, emailQuery, statusFilter, distributorIdFilter, createdAtFromFilter, createdAtToFilter, merchantIdFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      selectedTableData.value = []
      loadOrders()
      cache()
    }, 300),
  )

  return {
    tableColumns,
    searchQuery,
    emailQuery,
    options,
    invoiceListTable,
    statusFilter,
    distributorIdFilter,
    createdAtFromFilter,
    createdAtToFilter,
    merchantIdFilter,
    totalInvoiceListTable,
    loading,
    selectedTableData,
    getQuery,
    loadOrders,
    exportOrders,
  }
}
