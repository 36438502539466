<template>
  <v-card id="invoice-list">
    <!-- search -->
    <v-card-title class="d-block">
      <div class="d-flex align-center justify-space-between">
        <div>
          Search &amp; Filter
        </div>

        <v-btn
          color="primary"
          :loading="loading"
          @click="aside = 'exporter'"
        >
          <v-icon>{{ icons.mdiDownload }}</v-icon>
          <span>Export</span>
        </v-btn>
      </div>
    </v-card-title>

    <model-exporter-aside
      v-if="aside === 'exporter'"
      :active="true"
      :resource="getQuery()"
      :export-resource="exportOrders"
      @change="aisde = false"
      @aside="(bol) => { if (!bol) { aside = ''}}"
    >
      <template slot="title">
        Export Order
      </template>
    </model-exporter-aside>

    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search ID/Num"
          class="invoice-list-search me-3"
        ></v-text-field>

        <v-text-field
          v-model="emailQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="User email"
          class="invoice-list-search me-3"
        ></v-text-field>

        <v-select
          v-model="statusFilter"
          :items="statusOptions"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Select Status"
          class="invoice-list-status me-3"
        ></v-select>

        <v-autocomplete
          v-model="distributorIdFilter"
          :items="distributorOptions"
          single-line
          outlined
          dense
          hide-details
          clearable
          item-text="label"
          item-value="value"
          placeholder="Distributor"
          class="invoice-list-status me-3"
        ></v-autocomplete>

        <v-autocomplete
          v-model="merchantIdFilter"
          :items="merchantOptions"
          single-line
          outlined
          dense
          hide-details
          clearable
          item-text="label"
          item-value="value"
          placeholder="Merchant"
          class="invoice-list-status me-3"
        ></v-autocomplete>

        <v-menu
          ref="from"
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="createdAtFromFilter"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="createdAtFromFilter"
              label="From"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="createdAtFromFilter"
            no-title
            scrollable
          >
            <v-btn
              color="primary"
              @click="$refs.from.save(createdAtFromFilter)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-menu
          ref="to"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="createdAtToFilter"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="createdAtToFilter"
              label="To"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="createdAtToFilter"
            no-title
            scrollable
          >
            <v-btn
              color="primary"
              @click="$refs.to.save(createdAtToFilter)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      :headers="tableColumns"
      :items="invoiceListTable"
      :options.sync="options"
      :server-items-length="totalInvoiceListTable"
      :loading="loading"
      class="text-no-wrap"
    >
      <!-- trending header -->
      <template #[`header.trending`]>
        <v-icon size="22">
          {{ icons.mdiTrendingUp }}
        </v-icon>
      </template>

      <!-- id -->
      <template #[`item.id`]="{item}">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'checkout-order-view', params: { id: item.id } }"
        >
          {{ item.order_number }}
        </router-link>
        <div v-if="searchQuery">
          <small>{{ item.checkout.receipt_number }} | {{ item.checkout.custom_id }}</small>
        </div>
      </template>

      <!-- Nature -->
      <template #[`item.nature`]="{item}">
        <nature-icon
          :nature="item.nature"
        ></nature-icon>
      </template>

      <!-- user -->
      <template #[`item.user`]="{item}">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'channel-user-view', params: { id: item.user_id } }"
        >
          {{ item.user.email }}
        </router-link>
      </template>

      <!-- DIST. -->
      <template #[`item.distributor_id`]="{item}">
        {{ item.user.distributor.slug }}
      </template>

      <!-- merchant -->
      <template #[`item.merchant`]="{item}">
        <!-- <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'checkout-view', params: { id: item.id } }"
        > -->
        {{ t(item.merchant.name) }}
        <!-- </router-link> -->
      </template>

      <!-- total -->
      <template #[`item.total`]="{item}">
        {{ item.total | localPrice({ currency: item.currency }) }}
        <span v-if="item.checkout.total !== item.total">({{ item.checkout.total | localPrice({ currency: item.currency }) }})</span>
      </template>

      <!-- Status -->
      <template #[`item.status`]="{item}">
        <!-- chip -->
        <v-chip
          small
          :color="statusColor(item, item.checkout)"
          :class="`v-chip-light-bg ${statusColor(item, item.checkout)}--text font-weight-semibold`"
        >
          {{ displayStatusText(item, item.checkout) }}
        </v-chip>
        - {{ item.status }}
      </template>

      <!-- date -->
      <template #[`item.created_at`]="{item}">
        <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <!-- view  -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name: 'checkout-order-view', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Order</span>
          </v-tooltip>

          <!-- Receipt -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name: 'checkout-receipt-preview', params: { id: item.checkout.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiReceipt }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Receipt</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiDeleteOutline, mdiDotsVertical,
  mdiDownload,
  mdiEyeOutline,
  mdiPlus, mdiReceipt,
  mdiTrendingUp,
} from '@mdi/js'

import { t } from '@/plugins/i18n'
import {
  avatarText,
  displayStatusText,
  formatDate, localPrice,
  statusColor,
} from '@core/utils/filter'
import { ref } from '@vue/composition-api'

// composition function
import { ModelExporterAside, NatureIcon } from '@/components'
import { useDistributor } from '@/composables'
import useMerchantsList from '@/modules/merchant/views/useMerchantsList'
import useOrderList from './useOrderList'

export default {
  filters: { localPrice },
  components: { NatureIcon, ModelExporterAside },
  setup() {
    const {
      invoiceListTable, searchQuery, emailQuery, tableColumns, options, statusFilter, distributorIdFilter, merchantIdFilter, createdAtFromFilter, createdAtToFilter, totalInvoiceListTable, loading, selectedTableData, exportOrders, getQuery,
    } = useOrderList()

    const { distributorOptions, fetchDistributors } = useDistributor()
    const { merchantOptions, fetchMerchants } = useMerchantsList()
    fetchDistributors({ take: 999 })
    fetchMerchants({ take: 999 })

    const statusOptions = ref(['wait_for_payment', 'expired', 'cancelled', 'wait_for_confirmation', 'confirmed', 'completed'])

    const actions = ['Delete', 'Edit', 'Send']

    const selectedAction = ref('')
    const aside = ref(false)
    const menu1 = ref(false)
    const menu2 = ref(false)

    const actionOptions = [
      // { title: 'Download', icon: mdiDownloadOutline },
      // { title: 'Edit', icon: mdiPencilOutline },
      // { title: 'Delete', icon: mdiDeleteOutline },
      // { title: 'Duplicate', icon: mdiContentCopy },
    ]

    const checkType = data => {
      if (typeof data === 'number') return 'number'
      if (typeof data === 'string') return 'string'

      return 0
    }

    const handleAside = bol => {
      if (!bol) {
        aside.value = false
      }
    }

    return {
      getQuery,
      exportOrders,
      tableColumns,
      searchQuery,
      emailQuery,
      statusFilter,
      statusOptions,
      distributorIdFilter,
      merchantIdFilter,
      createdAtFromFilter,
      createdAtToFilter,
      distributorOptions,
      merchantOptions,
      options,
      totalInvoiceListTable,
      invoiceListTable,
      loading,
      actions,
      selectedTableData,
      actionOptions,

      selectedAction,
      aside,
      menu1,
      menu2,

      checkType,
      avatarText,
      formatDate,

      t,
      displayStatusText,
      statusColor,

      handleAside,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiReceipt,
        mdiDownload,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#invoice-list {
  .invoice-list-actions {
    max-width: 7.81rem;
  }
  .invoice-list-search {
    max-width: 10.625rem;
  }
  .invoice-list-status {
    max-width: 11.3rem;
  }
}
</style>
@/modules/merchant/merchant/useMerchantsList
